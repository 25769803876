import * as React from "react"
import {
  withPrismicPreviewResolver,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"
import Article from "../templates/article.js"
import Page from "../templates/page.js"

const linkResolver = doc => {
  return `/${doc.type}s/${doc.uid}`
}

const PreviewPage = ({ isPreview }) => {
  if (isPreview === false) return "Not a preview!"

  return <p>Loading</p>
}

export default withPrismicPreviewResolver(PreviewPage)
